<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader :title="id ? 'Update Commission' : 'Add New Commission'" />
        <div>
          <md-button :to="{ name: 'Commission' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate @submit.prevent="validateCommission">
          <div class="">
            <section class="row my-3" style="width: 100%">
              <div class="col-md-8">
                <div class="s-title">
                  <strong>Details</strong>
                </div>
                <md-field :class="getValidationClass('title')">
                  <label>Title</label>
                  <md-input v-model="form.title" required></md-input>
                  <span class="md-error" v-if="!$v.form.title.required"
                    >Section title is required</span
                  >
                </md-field>

                <md-field :class="getValidationClass('percent')">
                  <label>Percent</label>
                  <md-input
                    type="number"
                    v-model="form.percent"
                    required
                  ></md-input>
                  <span class="md-error" v-if="!$v.form.percent.required"
                    >Section Percent is required</span
                  >
                </md-field>
              </div>
            </section>
          </div>
          <mdc-button :loading="loading">{{
            id ? "Update" : "Create"
          }}</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("commission");
import moment from "moment";

export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      start_date: "",
    },
    loading: false,
    search: "",
    metadata: null,
    user: null,
    isOpen: false,
    fetching: true,
    time: null,
    filename: "",
    url: "",
    preview: "",
  }),
  validations: {
    form: {
      title: {
        required,
      },
      percent: {
        required,
      },
    },
  },
  methods: {
    ...mapActions([
      "createCommission",
      "updateCommission",
      "getMetaData",
      "getCommission",
    ]),
    async validateCommission() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let data = { ...this.form };

        try {
          this.loading = true;
          let call = this.id
            ? this.updateCommission(data)
            : this.createCommission(data);
          let msg = this.id ? "Commission Updated" : "Commission Created";
          await call;
          this.toast("success", msg);
          this.$router.push({ name: "Commission" });
          this.loading = false;
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    setUser(user) {
      this.isOpen = false;
      this.user = user;
    },
    // async getData() {
    //   this.fetching = true;
    //   const res = await this.getMetaData();
    //   console.log(res);
    //   this.fetching = false;
    //   this.metadata = res;
    // },
    handleFiles(e) {
      let file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.form.media = file;
      this.filename = file.name;
    },
    async submitSearch() {
      await this.$store.dispatch("user/getUsers", { keyword: this.search });
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    async getCommissionData() {
      if (this.id) {
        this.fetching = true;
        const data = await this.getCommission(this.id);
        // console.log(JSON.stringify(data.data));
        this.form = {
          id: data.data.id,
          title: data.data.title,
          percent: data.data.percent,
        };
        this.preview = data.preview;
        this.user = data.owner;
        this.fetching = false;
      }
    },
  },
  created() {
    // this.getCommissionData();
  },
  mounted() {
    this.getCommissionData();

    // this.getData();
  },
  computed: {
    users() {
      return this.$store.getters["user/users"];
    },
    getting() {
      return this.$store.getters["user/loading"];
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}

.s-title {
  margin-bottom: 20px;
  strong {
    font-weight: 700;
  }
}

.image-box {
  height: 130px;
  width: 100%;
  border: 2px dashed #d2d2d2;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }

  .preview {
    height: 100%;
    width: 100%;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

.users-search {
  position: relative;

  .users-card {
    width: 100%;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;

    .items {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background: #f9f9f9;
      }
    }
  }
  .selected {
    padding: 15px 10px;
    background: #e3ffe6;
    border-radius: 5px;
    font-weight: 700;
  }
}
</style>
